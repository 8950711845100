import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faCheckCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Input, Textarea } from 'digital-rabbit-cl';
import { isEmpty, isEmail } from 'validator';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

import ValidationWrapper from './ValidationWrapper';
import FormLabel from './FormLabel';
import FormValidator from '../utils/FormValidator';
import theme from '../theme.js';

const validator = new FormValidator([
  {
    field: 'name',
    method: (input) => isEmpty(input),
    validWhen: false,
    message: 'Name is required.',
  },
  {
    field: 'email',
    method: (input) => isEmpty(input),
    validWhen: false,
    message: 'Email is required.',
  },
  {
    field: 'email',
    method: (input) => isEmail(input),
    validWhen: true,
    message: 'Must be a valid email address.',
  },
  {
    field: 'message',
    method: (input) => isEmpty(input),
    validWhen: false,
    message: 'Message is required.',
  },
  {
    field: 'recaptcha',
    method: (input) => isEmpty(input),
    validWhen: false,
    message: 'ReCaptcha is required.',
  },
]);

const ReCaptchaDiv = styled.div`
  & #compact {
    display: block;
  }
  & #normal {
    display: none;
  }

  @media screen and (min-width: 320px) {
    & #compact {
      display: none;
    }
    & #normal {
      display: block;
    }
  }
`;

const ContactForm = () => {
  // form state
  const initialFormState = {
    name: '',
    email: '',
    message: '',
    recaptcha: '',
  };
  const [formState, setFormState] = useState(initialFormState);
  const { name, email, message } = formState;

  // other state
  const [sendingState, setSendingState] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = ({ target }) => {
    const { name, value } = target;

    // re-validate if form has been submitted
    if (submitted) {
      const validationStatus = validator.validate({
        ...formState,
        [name]: value,
      });
      setValidation(validationStatus);
    }

    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setSendingState(true);
    setSubmitted(true);

    const validationStatus = validator.validate(formState);
    setValidation(validationStatus);
    if (validationStatus.isValid) {
      let gtag = () => console.error('Google Analytics is not set up.');
      if (typeof window !== 'undefined') gtag = window.gtag;
      gtag('event', 'contactFormSubmitted', {
        event_category: 'contact',
        value: JSON.stringify(formState),
      });

      try {
        const formResponse = await fetch(
          '/.netlify/functions/handleSendFormData',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formState),
          }
        );

        if (!formResponse.ok) throw new Error('Network response was not ok.');

        setError(false);
        setSuccess(true);
      } catch (err) {
        console.error(err);
        setError('Error submitting form.');
      }
    }

    setSendingState(false);
  };

  if (success)
    return (
      <div
        style={{
          color: theme.colors.success,
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faCheckCircle}
          style={{ fontSize: '2em', marginRight: '.5em' }}
        />
        <div>Form submitted successfully!</div>
      </div>
    );
  return (
    <div style={{ maxWidth: '620px', width: '100%' }}>
      {error && (
        <div
          style={{
            color: theme.colors.error,
            display: 'flex',
            alignItems: 'center',
            padding: '0 0 1em',
          }}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{ fontSize: '2em', marginRight: '.5em' }}
          />
          <b>{error}</b>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <ValidationWrapper validation={validation.name}>
          {(error) => (
            <>
              <FormLabel
                name="Name"
                labelFor="name"
                style={{
                  color: error ? theme.colors.error : theme.colors.primary,
                  opacity: sendingState ? 0.3 : 1,
                }}
              />
              <Input
                id="name"
                name="name"
                color={error ? theme.colors.error : theme.colors.primary}
                disabled={sendingState}
                value={name}
                placeholder="Your name"
                placeholderOpacity={0.5}
                onChange={handleChange}
              />
            </>
          )}
        </ValidationWrapper>
        <ValidationWrapper validation={validation.email}>
          {(error) => (
            <>
              <FormLabel
                name="Email"
                labelFor="email"
                style={{
                  color: error ? theme.colors.error : theme.colors.primary,
                  opacity: sendingState ? 0.3 : 1,
                }}
              />
              <Input
                id="email"
                name="email"
                color={error ? theme.colors.error : theme.colors.primary}
                disabled={sendingState}
                value={email}
                placeholder="example@domain.com"
                placeholderOpacity={0.5}
                onChange={handleChange}
                type="email"
              />
            </>
          )}
        </ValidationWrapper>
        <ValidationWrapper validation={validation.message}>
          {(error) => (
            <>
              <FormLabel
                name="Message"
                labelFor="message"
                style={{
                  color: error ? theme.colors.error : theme.colors.primary,
                  opacity: sendingState ? 0.3 : 1,
                }}
              />
              <Textarea
                id="message"
                name="message"
                color={error ? theme.colors.error : theme.colors.primary}
                disabled={sendingState}
                value={message}
                placeholder="Your message"
                placeholderOpacity={0.5}
                onChange={handleChange}
                rows="5"
                style={{ resize: 'none' }}
              />
            </>
          )}
        </ValidationWrapper>
        <ValidationWrapper validation={validation.recaptcha}>
          {() => (
            <ReCaptchaDiv style={{ opacity: sendingState ? 0.3 : 1 }}>
              <ReCAPTCHA
                id="normal"
                sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
                onChange={(value) =>
                  handleChange({
                    target: { name: 'recaptcha', value: value || '' },
                  })
                }
                theme="dark"
              />
              <ReCAPTCHA
                id="compact"
                size="compact"
                sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
                onChange={(value) =>
                  handleChange({
                    target: { name: 'recaptcha', value: value || '' },
                  })
                }
                theme="dark"
              />
            </ReCaptchaDiv>
          )}
        </ValidationWrapper>
        <Button
          type="submit"
          color={theme.fonts.color}
          hoverColor={theme.colors.primary}
          hoverTextColor={theme.colors.secondary}
          disabled={sendingState || !validation.isValid}
          outline
        >
          {sendingState ? (
            <>
              <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
              &nbsp;Sending...
            </>
          ) : (
            `Submit`
          )}
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
