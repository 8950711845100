import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ContactForm from '../components/ContactForm';
import ResponsiveSection from '../components/ResponsiveSection';

const ContactPage = () => (
  <Layout pagePadding={true}>
    <SEO title="Contact" />
    <ResponsiveSection>
      <h1>Let's Talk.</h1>
      <p style={{ maxWidth: '620px', width: '100%' }}>
        Whether you want to start a project, or just start a conversation, we're&nbsp;all&nbsp;ears!&nbsp;
        <span style={{ lineHeight: 1 }} role="img" aria-label="waving hand icon">👋</span>
      </p>
      <ContactForm />
    </ResponsiveSection>
  </Layout>
);

export default ContactPage;
